<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Menüeintrag hinzufügen"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 d-inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="view_menu"
        v-model:expandedRows="view_menu_expanded"
        dataKey="_id"
        :loading="loading"
        :paginator="true"
        :rows="50"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Benutzern"
        responsiveLayout="scroll"
        sortField="index"
        :sortOrder="1"
        :reorderableColumns="true"
        @columnReorder="onColReorder"
        @rowReorder="onRowReorderRoot"
        @rowExpand="onRowExpand"
        @rowCollapse="onRowCollapse"
      >
        <template #header>
          <div class="table-header-container">
            <Button
              icon="pi pi-plus"
              label="Expand All"
              @click="expandAll"
              class="mr-2"
            />
            <Button
              icon="pi pi-minus"
              label="Collapse All"
              @click="collapseAll"
            />
          </div>
        </template>
        <Column :expander="true" headerStyle="width: 3rem" />
        <Column
          :rowReorder="true"
          headerStyle="width: 3rem"
          :reorderableColumn="false"
        />
        <Column field="index" header="Index" :sortable="true"></Column>
        <Column field="icon" header="Icon" :sortable="false">
          <template #body="slotProps">
            <Button
              v-if="slotProps.data.icon"
              :icon="slotProps.data.icon"
              class="p-button-rounded p-button-primary mr-2"
            />
          </template>
        </Column>
        <Column field="label" header="Name" :sortable="true"></Column>
        <Column field="desc" header="Beschreibung" :sortable="true"></Column>
        <Column field="category" header="Kategorie" :sortable="true"> </Column>
        <Column field="to" header="Pfad" :sortable="true"></Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editEntry(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeleteEntry(slotProps.data)"
            />
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="menu-subtable">
            <h5>
              {{ slotProps.data.label
              }}<span class="text-small"> > Submenü</span>
            </h5>
            <Button
              label="Submenü hinzufügen"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="openNewSub(slotProps.data._id)"
            />
            <DataTable
              :value="view_menu_temp[slotProps.data._id].items"
              dataKey="label"
              responsiveLayout="scroll"
              :reorderableColumns="true"
              @rowReorder="onRowReorderSub(slotProps.data._id, $event)"
            >
              <Column
                :rowReorder="true"
                headerStyle="width: 3rem"
                :reorderableColumn="false"
              />
              <Column field="id" header="Index" :sortable="false"></Column>
              <Column field="icon" header="Icon" :sortable="false">
                <template #body="slotProps">
                  <Button
                    v-if="slotProps.data.icon"
                    :icon="slotProps.data.icon"
                    class="p-button-rounded p-button-primary mr-2"
                  />
                </template>
              </Column>
              <Column field="label" header="Name"></Column>
              <Column field="category" header="Kategorie"></Column>
              <Column field="to" header="Pfad"></Column>
              <Column :exportable="false" style="min-width: 4rem">
                <template #body="slotPropsSub">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-success mr-2"
                    @click="editSubEntry(slotProps.data._id, slotPropsSub.data)"
                  />
                  <Button
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-warning"
                    @click="
                      confirmDeleteSubEntry(
                        slotProps.data._id,
                        slotPropsSub.data
                      )
                    "
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>

    <!-- ROOT DIALOG -->
    <Dialog
      v-model:visible="productDialog"
      :style="{ width: '450px' }"
      header="Menüeintrag bearbeiten"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="field">
        <label for="label">Name</label>
        <InputText
          id="label"
          v-model.trim="ioDialogData.label"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !ioDialogData.label }"
        />
        <small class="p-error" v-if="submitted && !ioDialogData.label"
          >Name <b>muss</b> ausgefüllt werden!</small
        >
      </div>
      <div class="field">
        <label for="to">Pfad</label>
        <InputText
          id="to"
          v-model.trim="ioDialogData.to"
          required="false"
          autofocus
        />
      </div>
      <div class="field">
        <label for="categoryStatus" class="mb-3">Kategorie</label>
        <Dropdown
          id="categoryStatus"
          v-model="ioDialogData.category"
          :options="getCategory"
          optionLabel="label"
          optionValue="key"
          placeholder="Wetterstation, Heizung,..."
        >
        </Dropdown>
      </div>
      <div class="field">
        <label for="desc">Beschreibung</label>
        <Textarea
          id="desc"
          v-model="ioDialogData.desc"
          required="true"
          rows="3"
          cols="20"
        />
      </div>

      <div class="field">
        <label for="icon">Icon</label>
        <Dropdown
          id="icon"
          v-model="ioDialogData.icon"
          :options="getIcons"
          optionLabel="key"
          optionValue="icon"
        >
          <template #value="slotProps">
            <div>
              <span :class="slotProps.value"></span>
              <span class="text-small"> [{{ slotProps.value }}]</span>
            </div>
          </template>

          <template #option="slotProps">
            <div>
              <span class="text-5xl" :class="slotProps.option.icon"></span>
              <span class="text-small"> [{{ slotProps.option.key }}]</span>
            </div>
          </template>
        </Dropdown>
      </div>

      <div v-if="ioDialogData._id" class="formgrid grid">
        <div class="field col">
          <label>ID</label>
          <InputText id="_id" v-model="ioDialogData._id" readonly />
        </div>
      </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveRootEntry"
        />
      </template>
    </Dialog>

    <!-- SUB DIALOG -->
    <Dialog
      v-model:visible="subDialog"
      :style="{ width: '450px' }"
      header="Submenü-Eintrag bearbeiten"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="field">
        <label for="label">Name</label>
        <InputText
          id="label"
          v-model.trim="ioDialogData.label"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !ioDialogData.label }"
        />
        <small class="p-error" v-if="submitted && !ioDialogData.label"
          >Name <b>muss</b> ausgefüllt werden!</small
        >
      </div>
      <div class="field">
        <label for="to">Pfad</label>
        <InputText
          id="to"
          v-model.trim="ioDialogData.to"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !ioDialogData.to }"
        />
        <small class="p-error" v-if="submitted && !ioDialogData.to"
          >Pfad <b>muss</b> ausgefüllt werden!</small
        >
      </div>
      <div class="field">
        <label for="categoryStatus" class="mb-3">Kategorie</label>
        <Dropdown
          id="categoryStatus"
          v-model="ioDialogData.category"
          :options="getCategory"
          optionLabel="label"
          optionValue="key"
          placeholder="Wetterstation, Heizung,..."
        >
        </Dropdown>
      </div>
      <div class="field">
        <label for="desc">Beschreibung</label>
        <Textarea
          id="desc"
          v-model="ioDialogData.desc"
          required="true"
          rows="3"
          cols="20"
        />
      </div>

      <div class="field">
        <label for="icon">Icon</label>
        <Dropdown
          id="icon"
          v-model="ioDialogData.icon"
          :options="getIcons"
          optionLabel="icon"
          optionValue="key"
        >
          <template #value="slotProps">
            <div>
              <span :class="slotProps.value"></span>
              <span class="text-small"> [{{ slotProps.value }}]</span>
            </div>
          </template>

          <template #option="slotProps">
            <div>
              <span :class="slotProps.option.icon"></span>
              <span class="text-small"> [{{ slotProps.option.key }}]</span>
            </div>
          </template>
        </Dropdown>
      </div>

      <div v-if="ioDialogData.id >= 0" class="formgrid grid">
        <div class="field col">
          <label>Index</label>
          <InputText id="id" v-model="ioDialogData.id" readonly />
        </div>
      </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideSubDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveSub"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <b>{{ ioDialogData.label }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProduct"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductsDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductsDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedProducts"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteSubEntryDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <b>{{ ioDialogData.label }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteSubEntryAbort"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSubEntry(ioDialogData)"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mongoResponseToast } from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";
import { mapGetters } from "vuex";

export default {
  name: "MenuTable",
  data() {
    return {
      loading: false,
      products: null,
      productDialog: false,
      subDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      deleteSubEntryDialog: false,
      product: {},
      config_io: {},
      ioDialogData: {},
      ioDialogID: null,
      view_menu: [],
      view_menu_temp: [],
      view_menu2: null,
      view_menu_selection: [],
      view_menu_index: [],
      view_menu_expanded: [],
      selectedProducts: null,
      selectedIOs: null,
      filters: {},
      submitted: false,
      intval_Value: null,
      columns: null,
      expandedRows: [],
    };
  },
  productService: null,
  created() {
    // this.productService = new ProductService();
    this.initFilters();
    this.columns = [
      { field: "label", header: "Name" },
      { field: "category", header: "Kategorie" },
      { field: "to", header: "Pfad" },
    ];
    this.$store.dispatch('types/loadUnitTypes');
    this.$store.dispatch('types/loadCategoryTypes');
    this.$store.dispatch('types/loadDepartmentTypes');
    this.$store.dispatch('types/loadIconTypes');
  },
  mounted() {
    // this.productService.getProducts().then((data) => (this.products = data));
    this.loading = true;
    this.getParameter();
  },
  beforeUnmount() {
    this.config_io = {};
    this.view_menu = [];
    this.view_menu_selection = [];
    this.view_menu_index = [],
    this.view_menu_expanded = [],
    this.view_menu_temp = [],
    this.filters = {};
  },
  computed: {
    ...mapGetters('types', ['getUnits', 'getCategory', 'getDepartments', 'getIcons']),
  },
  methods: {
    resetDialogData() {
      this.ioDialogData = {};
      this.view_menu_index = [];
      this.ioDialogID = null;
      // this.view_menu = [];
    },
    getMenuList() {
      this.loading = true;
      SocketioService.getViewMenu((err, response) => {
        if (!err && response !== null) {
          this.view_menu = [ ...response ];
          Object.entries(this.view_menu).forEach(([, value], index) => {
            if (index === 0) {
              this.view_menu_selection[0] = "ROOT";
            }
            this.view_menu_selection[index + 1] = value.label;
            this.view_menu_index.push(index);
          });
          this.loading = false;
        }
      });
    },
    getParameter() {
      this.getMenuList();
      // this.getUserGroupList();
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.resetDialogData();
      this.submitted = false;
      this.productDialog = true;
    },
    openNewSub(id) {
      this.resetDialogData();
      // this.getMenuList();
      this.ioDialogID = id;
      this.submitted = false;
      this.subDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
      // this.getUserList();
      this.resetDialogData();
      this.getMenuList();
    },
    saveRootEntry() {
      this.submitted = true;
      this.loading = true;

      if (this.ioDialogData.label.trim()) {
        let _index = this.findIndexById(this.ioDialogData._id);
        if (_index >= 0) {
          this.view_menu[_index] = this.ioDialogData;
          SocketioService.setViewMenu(this.view_menu[_index], (err, response) => {
            if (!err && response !== null) {
              this.loading = false;
              const result = mongoResponseToast(response, this.$root);
              if (result) this.getMenuList();
            }
          });
        } else {
          this.ioDialogData.root = true;
          this.ioDialogData.items = [];
          this.ioDialogData.parent = 'ROOT';
          this.ioDialogData.index = this.view_menu.length;
          SocketioService.setViewMenu(this.ioDialogData, (err, response) => {
            if (!err && response !== null) {
              this.loading = false;
              const result = mongoResponseToast(response, this.$root);
              if (result) this.getMenuList();
            }
          });
        }
        // clearInterval(this.intval_Value);
        this.productDialog = false;
        this.resetDialogData();
      }
    },
    editEntry(ioData) {
      // this.ioDialogData = { ...ioData };
      this.getMenuList();
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.productDialog = true;
      // this.intval_Value = setInterval(this.updateOpenValue, 1000);
    },
    editSubEntry(id, ioData) {
      // this.ioDialogData = { ...ioData };
      this.getMenuList();
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.ioDialogID = id;
      this.subDialog = true;
      // this.intval_Value = setInterval(this.updateOpenValue, 1000);
    },
    saveSub() {
      this.submitted = true;
      this.loading = true;

      console.log(
        this.ioDialogData.label,
        this.ioDialogID,
        this.ioDialogData.id
      );

      if (this.ioDialogData.label.trim() && this.ioDialogID !== null) {
        let id = this.ioDialogID;
        let _index = this.findIndexById(id);
        let subId;
        if (this.ioDialogData.id >= 0) {
          subId = this.ioDialogData.id;
        } else {
          if (this.view_menu[_index].items !== null) {
            subId = this.view_menu[_index].items.length;
          } else {
            this.view_menu[_index].items = [];
            subId = 0;
          }
          // console.log(subId);
        }

        if (_index >= 0) {
          // console.log(_index, id);
          // console.log("subid", subId);
          // console.log(this.ioDialogData);
          // console.log(this.view_menu[_index]);
          this.ioDialogData.id = subId;
          this.view_menu[_index].items[subId] = this.ioDialogData;
          // console.log(this.view_menu[_index].items[subId]);
          // console.log(this.view_menu[_index]);
          SocketioService.setViewMenu(this.view_menu[_index], (err, response) => {
            if (!err && response !== null) {
              this.loading = false;
              const result = mongoResponseToast(response, this.$root);
              if (result) this.getMenuList();
              console.log(response);
              this.view_menu_temp[id] = this.view_menu[_index];
              this.resetDialogData();
              this.getMenuList();
              this.subDialog = false;
            }
          });
        } else {
          // console.log(this.ioDialogData[id].submenu);
          const newIndex = this.ioDialogData[id].submenu.length;
          // console.log(newIndex);
          SocketioService.setViewMenu(this.ioDialogData[id].submenu[newIndex], (err, response) => {
            if (!err && response !== null) {
              this.loading = false;
              const result = mongoResponseToast(response, this.$root);
              if (result) this.getMenuList();
              console.log(response);
              this.resetDialogData();
              this.getMenuList();
              this.subDialog = false;
            }
          });
        }
        // clearInterval(this.intval_Value);
      }
    },
    hideSubDialog() {
      this.subDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
      // this.getUserList();
      this.resetDialogData();
      this.getMenuList();
    },
    confirmDeleteEntry(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteProductDialog = true;
    },
    confirmDeleteSubEntry(id, ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogID = id;
      this.ioDialogData = _obj;
      this.deleteSubEntryDialog = true;
    },
    deleteProduct() {
      // this.products = this.products.filter((val) => val.id !== this.product.id);
      let _index = this.findIndexById(this.ioDialogData._id);
      console.log(this.view_menu[_index]);
      if (_index >= 0) {
        this.loading = true;
        this.view_menu[_index] = this.ioDialogData;
        SocketioService.delViewMenu(this.view_menu[_index], (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getMenuList();
          }
        });
      }
      this.deleteProductDialog = false;
      this.resetDialogData();
    },
    deleteSubEntry(ioData) {
      // this.products = this.products.filter((val) => val.id !== this.product.id);

      let id = this.ioDialogID;
      let _index = this.findIndexById(id);
      let _id = ioData.id;

      if (_index >= 0) {
        this.loading = true;
        this.view_menu[_index].items.splice(_id, 1);
        if (this.view_menu[_index].items.length === 1) this.view_menu[_index].items[0].id = 0;
        console.log(this.view_menu[_index])
        SocketioService.setViewMenu(this.view_menu[_index], (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getMenuList();
            this.deleteSubEntryDialog = false;
            this.view_menu_temp[id] = this.view_menu[_index];
            this.resetDialogData();
            this.getMenuList();
            this.subDialog = false;
          }
        });
      }
      this.deleteProductDialog = false;
      this.resetDialogData();
    },
    deleteSubEntryAbort() {
      this.deleteSubEntryDialog = false;
      this.resetDialogData();
    },
    updateOpenValue() {
      // let _index = this.findIndexById(this.ioDialogData._id);
      // if (_index >= 0) {
      //   // SocketioService.getStatusIO((response) => {
      //   //   this.ioDialogData.value.value = response[_index].value.value;
      //   // });

      // }
      SocketioService.getSingleValue(this.ioDialogData._id, (response) => {
        this.ioDialogData.value.value = response.value.value;
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.view_menu.length; i++) {
        if (this.view_menu[i]._id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      SocketioService.removeValue(this.selectedIOs);
      this.deleteProductsDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    onColReorder() {
      this.$toast.add({
        severity: "success",
        summary: "Column Reordered",
        life: 3000,
      });
    },
    onRowReorderRoot(event) {
      this.view_menu = event.value.map((entry, index) => {
        entry.index = index;
        return entry;
      });
      // console.log(event.value);
      // console.log(this.view_menu);
      SocketioService.setViewMenuArray(this.view_menu, (err, response) => {
        if (!err && response !== null) {
          this.$toast.add({
            severity: "success",
            summary: "Reihenfolge geändert",
            life: 3000,
          });
          this.getMenuList();
        }
      });      
    },
    onRowReorderSub(id, event) {
      this.view_menu_temp[id].items = event.value.map((entry, index) => {
        entry.id = index;
        return entry;
      });
      let _index = this.findIndexById(id);
      if (_index >= 0) {
        this.loading = true;
        // this.view_menu[_index].submenu = this.view_menu2.submenu;
        this.view_menu[_index].items = this.view_menu_temp[id].items;
        console.log(this.view_menu[_index]);
        SocketioService.setViewMenu(this.view_menu[_index], (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            this.$toast.add({
              severity: "success",
              summary: "Reihenfolge geändert",
              life: 3000,
            });
            this.getMenuList();
          }
        });
      }
    },
    onRowExpand(event) {
      this.view_menu2 = event.data;
      let _id = event.data._id;
      this.view_menu_temp[_id] = event.data;
      // console.log(this.view_menu_temp);
      // this.$toast.add({
      //   severity: "info",
      //   summary: "Product Expanded",
      //   detail: event,
      //   life: 3000,
      // });
    },
    onRowCollapse(event) {
      delete this.view_menu_temp[event.data._id];
      // this.$toast.add({
      //   severity: "success",
      //   summary: "Product Collapsed",
      //   detail: event.data.name,
      //   life: 3000,
      // });
    },
    expandAll() {
      // this.expandedRows = this.cars.filter((car) => car.vin);
      // this.$toast.add({
      //   severity: "success",
      //   summary: "All Rows Expanded",
      //   life: 3000,
      // });
    },
    collapseAll() {
      // this.expandedRows = null;
      // this.$toast.add({
      //   severity: "success",
      //   summary: "All Rows Collapsed",
      //   life: 3000,
      // });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>